import React ,{ useEffect, useRef }  from 'react';
import { useLocation, useNavigate, useParams,Link } from 'react-router-dom';
import quizContent from './content';
import sponsored from './sponsored';
import coinCookieName from "../Servies"
import Cookies from 'js-cookie';

const PlayNow = () => {
  const{category,id}= useParams();
  const location = useLocation();
  const quizCategories = location.state.quizCategories;
  const topOfPageRef = useRef(null);

  // const quiz_pic_url = location.state.quiz_pic_url;
  // const coin_text = location.state.coin_text;
  // const win_coins = location.state.win_coins;
  // const entry_coins = location.state.entry_coins;
  // const quiz_category = location.state.quiz_category;

  const navigate = useNavigate();
  const {
    quiz_pic_url,
    coin_text,
    win_coins,
    entry_coins,
    quiz_category,
  } = quizCategories;


  let value = parseInt(Cookies.get(coinCookieName.coinCookieName))
  let coins = parseInt(win_coins)
  const handlePlayNowClick = (category) => {
    // Scroll to the top when "Play Now" button is clicked
    topOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
    Cookies.set(coinCookieName.coinCookieName,value-coins)
    navigate(`/quiz/${category}/${id}`, {
      state: {
        quizCategories: quizCategories,
        quiz_pic_url: category.quiz_pic_url,
        coin_text: category.coin_text ,
        win_coins: category.win_coins,
        entry_coins: category.entry_coins,
        quiz_category: category.quiz_category,
        num_of_questions:category.num_of_questions,
        timer_value:category.timer_value,
        correct_ans_point:category.correct_ans_point,
        wrong_ans_point:category.wrong_ans_point
      },
    });   
  };

useEffect(() => {
  // Scroll to the top when the component mounts
  topOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
}, []);

  return (
    <div className="page_container" ref={topOfPageRef} style={{ top: 0 }} tabIndex="-1">
    <div className="row headrow">
      <div className="quizhead">
        <Link className="quizhead" to="/home">
          <svg className="svg-inline--fa fa-arrow-left fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg>
        </Link>
      </div>
    </div>

      {/* PlayNow Section */}
    <div className="playNows_playNows" >
        <div className="playNow_stage">
        <div className=" playNow_box playNow_bounce">
        <h1 className="playNows_title">{quiz_category}</h1>
        </div>
      </div>
        <div className="lottie_lottieCoins">
          <div className="playNow_thumbnialImg">
        <img
                alt={`Play ${quiz_category} Online Quiz`}
                title={`Play ${quiz_category} Online Quiz`}
              fetchpriority="high"  // <-- Change to fetchPriority if intended
              width="100"
              height="100"
              decoding="async"
              data-nimg="1"
              srcSet={ `/assets/images/icon/${quiz_pic_url}`}
              style={{ color: 'transparent' }}
            />
    </div></div>
    
        <h3 style={{marginLeft:"5px",display:"flex",justifyItems:"center"}}> {coin_text} {win_coins} 
        <img
                title="quizpopa_coin"
                alt="coin"
                loading="lazy"
                width="19"
                height="19"
                decoding="async"
                data-nimg="1"
                srcSet="https://pngimg.com/uploads/coin/coin_PNG36871.png"
                style={{ color: 'transparent',marginLeft:"5px"}}
              /></h3>
        <p>You have got {quizCategories.timer_value} seconds to answer all questions. Answer as many questions as you can. Entry fee will be {entry_coins} coins</p>
          <p>Join and save the coins you win! It's free & safe!</p>
      </div>
      <button
          className="playNow_btn playNow_shine playNow_animated undefined"
          onClick={() => handlePlayNowClick(category)}>
          Play Now
        </button>
      {/* Ad Section */}
      <div>
      {sponsored.items.toLowerCase() === 'true' && (
        <div className="page_ad">
          <div className="page_sponsored">{sponsored.title}</div>
        </div>
      )}
    </div>

        <section className="contentList_contentList">
        <h2>{quizContent.title}</h2>
        <ul>
          {quizContent.items.map((item, index) => (
            <li key={index}style={{marginBottom:"-10px"}}>{item}</li>
          ))}
        </ul>
        </section>
    </div>
  );
};

export default PlayNow;

